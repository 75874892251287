import '../../../styles/apps/clock.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../app elements/AppHeader.jsx';
import CloseApp from '../app elements/CloseApp.jsx';

export default function Clock() {
  const navigate = useNavigate();
  const [currentTime, setCurrentTime] = useState(new Date());
  const [showExplainText, setShowExplainText] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [animationClass, setAnimationClass] = useState('');
  const [displayMode, setDisplayMode] = useState(() => {
    // Retrieve the last used display mode from localStorage (default to 0 if not found)
    // 0: Binary, 1: Regular, 2: Military
    return parseInt(localStorage.getItem('lastClockMode')) || 0;
  });

  useEffect(() => {
    const clockInterval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(clockInterval);
  }, []);

  // Save displayMode to localStorage when it changes
  useEffect(() => {
    localStorage.setItem('lastClockMode', displayMode);
  }, [displayMode]);

  const animationsIn = [
    'fadeSlideIn', 'scaleFadeIn', 'spinRevealIn', 'dropBounceIn',
    'flipIn', 'zoomIn', 'skewSlideIn', 'wobbleIn'
  ];
  
  const animationsOut = [
    'fadeSlideOut', 'scaleFadeOut', 'spinRevealOut', 'dropBounceOut',
    'flipOut', 'zoomOut', 'skewSlideOut', 'wobbleOut'
  ];

  const toggleExplanationText = () => {
    if (showExplainText) {
      const randomExitAnimation = animationsOut[Math.floor(Math.random() * animationsOut.length)];
      setAnimationClass(randomExitAnimation);
      setTimeout(() => {
        setShowExplainText(false);
        setIsVisible(false);
      }, 400); // Match this to your animation duration
    } else {
      const randomEnterAnimation = animationsIn[Math.floor(Math.random() * animationsIn.length)];
      setAnimationClass(randomEnterAnimation);
      setShowExplainText(true);
      setIsVisible(true);
    }
  };

  const getBinaryRepresentation = (num) => {
    return [8, 4, 2, 1].map(rowValue => {
      if (num >= rowValue) {
        num -= rowValue;
        return true;
      }
      return false;
    });
  };

  const renderColumn = (binaryArray) => {
    return (
      <div className="dot-column">
        {binaryArray.map((isOn, index) => (
          <div key={index} className={`dot ${isOn ? 'on' : 'off'}`}></div>
        ))}
      </div>
    );
  };

  const renderClockComponent = () => {
    switch (displayMode) {
      case 0:
        return renderTimeAsDots(currentTime);
      case 1:
        return renderRegularTime(currentTime, false);
      case 2:
        return renderRegularTime(currentTime, true);
      default:
        return renderTimeAsDots(currentTime);
    }
  };

  const renderTimeAsDots = (time) => {
    let hours = time.getHours();
    const minutes = time.getMinutes();
    const seconds = time.getSeconds();

    if (displayMode === 1) { // Regular time mode (12-hour format)
      hours = hours % 12 || 12;
    }

    const hourTens = getBinaryRepresentation(Math.floor(hours / 10));
    const hourOnes = getBinaryRepresentation(hours % 10);
    const minuteTens = getBinaryRepresentation(Math.floor(minutes / 10));
    const minuteOnes = getBinaryRepresentation(minutes % 10);
    const secondTens = getBinaryRepresentation(Math.floor(seconds / 10));
    const secondOnes = getBinaryRepresentation(seconds % 10);

    return (
      <div className="dot-grid">
        {renderColumn(hourTens)}
        {renderColumn(hourOnes)}
        <div className="space" />
        {renderColumn(minuteTens)}
        {renderColumn(minuteOnes)}
        <div className="space" />
        {renderColumn(secondTens)}
        {renderColumn(secondOnes)}
      </div>
    );
  };

  const renderRegularTime = (time, isMilitary) => {
    const hours = isMilitary ? time.getHours() : (time.getHours() % 12 || 12);
    const minutes = time.getMinutes().toString().padStart(2, '0');
    const seconds = time.getSeconds().toString().padStart(2, '0');
    const period = !isMilitary && (time.getHours() >= 12 ? 'PM' : 'AM');

    return (
      <div className="large-time-display">
        {hours}:{minutes}:{seconds} {!isMilitary && period}
      </div>
    );
  };

  const renderExplanationText = () => {
    let explanationContent = null;

    switch (displayMode) {
      case 0:
        explanationContent = (
          <div className="border-top">
            <h5>BINARY CLOCK</h5>
            <p>This clock is a tribute to my coding teachers: <strong>Ray LePine</strong> and <strong>the Papadapamuses</strong>.</p>
            <button className="explainBtn" onClick={toggleExplanationText}>
              {showExplainText ? 'Hide Explanation' : 'Learn More'}
            </button>
            {showExplainText && (
              <div className={`explainText ${animationClass} ${showExplainText ? 'show' : 'hide'}`}>
                <p><strong>WHAT IS THIS?</strong></p>
                <p>A binary clock displays time where each digit is shown in a binary format.</p>
                <p>Each column represents a single digit for hour, minute, and second, like this:<strong> H H M M S S</strong>.</p>
                <p>From bottom to top, each row of dots represents a binary unit, meaning, each row should be read as: <strong>1, 2, 4, 8. </strong></p>
                <p>In this example, the column shows "5" because 1 + 4 = 5.</p>
                <p>&#9675;<br/>&#9679;<br/>&#9675;<br/>&#9679;<br/><br/></p>
              </div>
            )}
          </div>
        );
        break;
      case 1:
        explanationContent = (
          <div className="border-top">
            <h5>12-HOUR FORMAT</h5>
            <button className="explainBtn" onClick={toggleExplanationText}>
              {showExplainText ? 'Hide Explanation' : 'Learn More'}
            </button>
            {showExplainText && (
              <div className={`explainText ${animationClass} ${showExplainText ? 'show' : 'hide'}`}>
                <p>This format displays time in AM and PM. The day is divided into two 12-hour periods: from 12-midnight to 11:59 (AM) and from 12-noon to 11:59 (PM).</p>
                <p>AM stands for <strong>"Ante Meridiem,"</strong> which is Latin for "before noon." PM stands for <strong>"Post Meridiem,"</strong> which means "after noon."</p>
                <p>Why 12? The 12-hour format has roots in ancient civilizations, particularly the Egyptians and Romans. Humans have been marking time the same way since.</p>
              </div>
            )}
          </div>
        );
        break;
      case 2:
        explanationContent = (
          <div className="border-top">
            <h5>24-HOUR FORMAT</h5>
            <button className="explainBtn" onClick={toggleExplanationText}>
              {showExplainText ? 'Hide Explanation' : 'Learn More'}
            </button>
            {showExplainText && (
              <div className={`explainText ${animationClass} ${showExplainText ? 'show' : 'hide'}`}>
                <p>This format is commonly used in military and some international contexts. The hours run from 0 to 23, making it easier to avoid confusion when meaning morning or evening hours.</p>
                <p>Many countries, especially in Europe and Asia, prefer the 24-hour clock. It’s often used in public transportation timetables, official documents, and digital displays.</p>
                <p>To convert this time into the more common 12-hour format, simply read anything before hour 12 "as is." For an hour at 13 or higher, subtract 12.</p>
                <p>For example, the military time 09:00 is 9am because 9 is before 13. However, the military time 14:15 converts to 2:15pm because 14 - 12 equals 2.</p>
              </div>
            )}
          </div>
        );
        break;
      default:
        return null;
    }

    return explanationContent;
  };

  const handleChangeDisplayMode = () => {
    setDisplayMode((prevMode) => (prevMode + 1) % 3);
  };

  return (
    <div className="app-container">
      <header className="app-header">
        <Header title="Clock" />
        <CloseApp navigate={navigate} />
      </header>
      <main className="app-contents">
        {renderClockComponent()}
        <button className="change-time-button" onClick={handleChangeDisplayMode}>Change Time Display</button>
        {renderExplanationText()}
      </main>
    </div>
  );
}
