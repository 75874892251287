import '../../../styles/apps/portfolio.css';
import Header from '../app elements/AppHeader.jsx';
import CloseApp from '../app elements/CloseApp.jsx';
import { useNavigate } from 'react-router-dom';

export default function Portfolio() {
    const navigate = useNavigate()
    const portfolioItems = [
        {
            title: "Intranet System + MS Sharepoint",
            category: "Development",
            thumbnail: "./portfolio_assets/sharepoint.webp",
            altText: "Thumbnail for video showcasing an intranet system Jeffrey created with Sharepoint by Microsoft.",
            videoPath: "../portfolio_assets/videos/SharepointIntranet.webm",
            videoIcon: "../portfolio_assets/icons/video.svg",
            infoIcon: "../portfolio_assets/icons/info.svg",
            webIcon: "../portfolio_assets/icons/website.svg",
            liveUrl: null,
        },
        {
            title: "Power Automate Overview",
            category: "Development",
            thumbnail: "./portfolio_assets/powerAutomate.webp",
            altText: "Thumbnail for video showcasing a few business practices Jeffrey has been able to automate using Microsoft Power Automate.",
            videoPath: "../portfolio_assets/videos/PowerAutomateOverview.webm",
            videoIcon: "../portfolio_assets/icons/info.svg",
            videoIcon: "../portfolio_assets/icons/video.svg",
            infoIcon: "../portfolio_assets/icons/info.svg",
            webIcon: null,
            liveUrl: null, // No live website for this item
        },
        {
            title: "Magazine Series to Web",
            category: "Development",
            thumbnail: "./portfolio_assets/ICOM.png",
            altText: "Thumbnail for video demonstrating a web system Jeffrey built to essentially convert a printed magazine to a website.",
            videoPath: "../portfolio_assets/videos/ICOMtoWeb.webm",
            videoIcon: "../portfolio_assets/icons/video.svg",
            infoIcon: "../portfolio_assets/icons/info.svg",
            webIcon: "../portfolio_assets/icons/website.svg",
            liveUrl: "https://www.icomatters.ico.edu/",
        },
        {
            title: "Multi-Media Campaign",
            category: "Marketing & Web Design",
            thumbnail: "./portfolio_assets/marketingCampaign.webp",
            altText: "Thumbnail for video explaining a successful multi-media marketing campaign.",
            videoPath: "../portfolio_assets/videos/WelcomeCampaign.webm",
            videoIcon: "../portfolio_assets/icons/video.svg",
            infoIcon: "../portfolio_assets/icons/info.svg",
            webIcon: null,
            liveUrl: null,
        },
        {
            title: "College Recruitment Website",
            category: "Marketing & Web Design",
            thumbnail: "./portfolio_assets/whyICO.webp",
            altText: "Thumbnail for video demonstrating a traditional website Jeffrey created for college recruitment purposes.",
            videoPath: "../portfolio_assets/videos/WHY_ICO.webm",
            videoIcon: "../portfolio_assets/icons/video.svg",
            infoIcon: "../portfolio_assets/icons/info.svg",
            webIcon: "../portfolio_assets/icons/website.svg",
            liveUrl: "https://www.why.ico.edu/",
        },
        // {
        //     title: "Freelance Work",
        //     category: "Design (Web)",
        //     thumbnail: "./portfolio/whyICO.jpg",
        //     altText: "Thumbnail for freelance project examples",
        //     videoPath: "../portfolio/videos/WHY_ICO.webm",
        //     videoIcon: "../portfolio/icons/video.svg",
        //     infoIcon: "../portfolio/icons/info.svg",
        //     webIcon: null,
        //     liveUrl: null,
        // },
        // {
        //     title: "Cultish",
        //     category: "Mobile App",
        //     thumbnail: "./laptopAssets/cultish/cultish_app_icon_logo.png",
        //     altText: "App icon for my first app named Cultish",
        //     videoPath: "../portfolio/videos/WHY_ICO.webm",
        //     videoIcon: "../portfolio/icons/video.svg",
        //     infoIcon: "../portfolio/icons/info.svg",
        //     webIcon: null,
        //     liveUrl: null,
        // },
        // {
        //     title: "NoiseyMoji",
        //     category: "Mobile App",
        //     thumbnail: "./laptopAssets/attemptedIdeas/noiseymoji_logo.png",
        //     altText: "Thumbnail for freelance project examples",
        //     videoPath: "../portfolio/videos/WHY_ICO.webm",
        //     videoIcon: "../portfolio/icons/video.svg",
        //     infoIcon: "../portfolio/icons/info.svg",
        //     webIcon: null,
        //     liveUrl: null,
        // },
        // {
        //     title: "Augmented Occasions",
        //     category: "Mobile Apps",
        //     thumbnail: "./laptopAssets/attemptedIdeas/augmentedOccasions.png",
        //     altText: "Thumbnail for freelance project examples",
        //     videoPath: "../portfolio/videos/WHY_ICO.webm",
        //     videoIcon: "../portfolio/icons/video.svg",
        //     infoIcon: "../portfolio/icons/info.svg",
        //     webIcon: null,
        //     liveUrl: null,
        // },
    ];
    // Group items by category
    const groupedItems = portfolioItems.reduce((acc, item) => {
        acc[item.category] = acc[item.category] || [];
        acc[item.category].push(item);
        return acc;
    }, {});


    // const handleVideoClick = (videoPath) => {
    //     const videoElement = document.createElement("video");
    //     videoElement.src = videoPath;
    //     videoElement.controls = true;
    //     videoElement.style.width = "100vw";
    //     videoElement.style.height = "100vh";
    //     videoElement.style.position = "fixed";
    //     videoElement.style.top = "0";
    //     videoElement.style.left = "0";
    //     videoElement.style.zIndex = "9999";
    //     videoElement.style.backgroundColor = "black";
    //     document.body.appendChild(videoElement);

    //     videoElement.play();

    //     // Remove video element after playback
    //     videoElement.onended = () => {
    //         document.body.removeChild(videoElement);
    //     };

    //     videoElement.onclick = () => {
    //         document.body.removeChild(videoElement);
    //     };
    // };

    return (
        <div className="app-container">
            <header className="app-header">
                <Header title="Portfolio" />
                <CloseApp navigate={navigate} />
            </header>
            <main className="app-contents">
                {Object.keys(groupedItems).map((category) => (
                    <section key={category} className="portfolio-section">
                        <div className="category-header">
                            <h3 className="portfolio-category">{category}</h3>
                        </div>
                        <div className="portfolio-thumbnails">
                            {groupedItems[category].map((item, index) => (
                                <div key={index} className="portfolio-item">
                                    <img
                                        src={item.thumbnail}
                                        alt={item.altText}
                                        className="thumbnail"
                                        onClick={() => window.open(item.videoPath, "_self")}
                                    />
                                    <span>{item.title}</span>
                            {/* <div className="portfolio-icons">
                                {item.videoIcon && item.videoPath && (
                                    <img
                                        src={item.videoIcon}
                                        alt="click for video"
                                        onClick={() => window.open(item.videoPath, "_self")}
                                    />
                                )}
                                {item.infoIcon && (
                                    <img
                                        src={item.infoIcon}
                                        alt="click for more information"
                                    />
                                )}
                                {item.liveUrl && item.webIcon && (
                                    <img
                                        src={item.webIcon}
                                        alt="click to view this website"
                                        onClick={() => window.open(item.liveUrl, "_blank")}
                                    />
                                )}
                            </div> */}
                        </div>
                        ))}
                    </div>
                </section>
            ))}
        </main>
        </div>
    );
}