import '../styles/mobileStyles.css'
import { Route, Routes, Navigate } from "react-router-dom"
import React, { Suspense } from 'react'
import ErrorBoundary from './ErrorBoundary.jsx'
import Preloader from '../preloader/Preloader.jsx'

const IntroPopup = React.lazy(() => import('../preloader/IntroPopup.jsx'))
const MainMenuPage = React.lazy(() => import('../webpages/MainMenu.jsx'))
const ContactPage = React.lazy(() => import('../webpages/Contact.jsx'))
const InterviewPage = React.lazy(() => import('../webpages/Interview/InterviewHome.jsx'))
const LaptopHome = React.lazy(() => import('../webpages/Laptop/LaptopHome.jsx'))
const MobileHome = React.lazy(() => import('../webpages/Mobile/MobileHome.jsx'))
import CalendarApp from '../webpages/Mobile/apps/Calendar.jsx'
import CalculatorApp from '../webpages/Mobile/apps/Calculator.jsx'
import ClockApp from '../webpages/Mobile/apps/Clock.jsx'
import EmailApp from '../webpages/Mobile/apps/Email.jsx'
import MusicApp from '../webpages/Mobile/apps/Music.jsx'
import NotesApp from '../webpages/Mobile/apps/Notes.jsx'
import PhoneApp from '../webpages/Mobile/apps/Phone.jsx'
import PhotosApp from '../webpages/Mobile/apps/Photos.jsx'
import ResumeContent from "../webpages/Laptop/windows/Resume.jsx"
import PortfolioApp from '../webpages/Mobile/apps/Portfolio.jsx'
import WeatherApp from '../webpages/Mobile/apps/Weather.jsx'

export default function MainNav() {
    return (
      <Suspense fallback={<Preloader />}>
        <ErrorBoundary>
          <Routes>
            <Route path="/" element={ null } />
            <Route path="/intro" element={<IntroPopup />} />
            <Route path="/menu" element={<MainMenuPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/interview" element={<InterviewPage />} />
            <Route path="/laptop" element={<LaptopHome />} />
            <Route path="/mobile" element={<MobileHome />} />
            <Route path="/resume" element={<ResumeContent />} />

            {/* Mobile Apps */}
            <Route path="/calendar" element={<CalendarApp />} />
            <Route path="/calculator" element={<CalculatorApp />} />
            <Route path="/clock" element={<ClockApp />} />
            <Route path="/email" element={<EmailApp />} />
            <Route path="/music" element={<MusicApp />} />
            <Route path="/notes" element={<NotesApp />} />
            <Route path="/phone" element={<PhoneApp />} />
            <Route path="/photos" element={<PhotosApp />} />
            <Route path="/portfolio" element={<PortfolioApp />} />
            <Route path="/weather" element={<WeatherApp />} />

            {/* Wildcard Redirect to Handle Any Refresh Issues */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </ErrorBoundary>
      </Suspense>
    );
}
